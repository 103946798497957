var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DashboardEmployeeVolume" },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.dataPointsRequestsFinished
            ? _c(
                "div",
                {
                  key: "loading-indicator-data-points",
                  staticClass: "row row--align-center row--justify-center"
                },
                [
                  _c("i", {
                    staticClass: "fa fa-processing fa-spin fa--none fs-60"
                  })
                ]
              )
            : _c("div", { key: "charts", staticClass: "column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "DashboardEmployeeVolume__data-points row row--wrap"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "DashboardEmployeeVolume__data-point-container column column--width-auto"
                      },
                      [
                        _c("label", { staticClass: "fs-24 mb-4" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                new Intl.NumberFormat("en-US", {
                                  currency: "USD",
                                  style: "currency"
                                }).format(
                                  _vm.dataPoints.volumeThisMonth.currentCycle
                                )
                              ) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          {
                            staticClass:
                              "DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase"
                          },
                          [
                            _vm.dataPoints.volumeThisMonth.cycleDifference
                              .text !== "0%"
                              ? _c(
                                  "svg",
                                  {
                                    class: [
                                      "DashboardEmployeeVolume__svg",
                                      {
                                        "DashboardEmployeeVolume__svg--green":
                                          _vm.dataPoints.volumeThisMonth
                                            .cycleDifference.positivity,
                                        "DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip": !_vm
                                          .dataPoints.volumeThisMonth
                                          .cycleDifference.positivity
                                      }
                                    ],
                                    attrs: {
                                      fill: "none",
                                      viewBox: "0 0 11 7",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "clip-rule": "evenodd",
                                        d:
                                          "M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z",
                                        fill: "#34AA44",
                                        "fill-rule": "evenodd"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: [
                                  "fs-12 mr-3",
                                  {
                                    "fc-green":
                                      _vm.dataPoints.volumeThisMonth
                                        .cycleDifference.positivity &&
                                      _vm.dataPoints.volumeThisMonth
                                        .cycleDifference.text !== "0%",
                                    "fc-light":
                                      _vm.dataPoints.volumeThisMonth
                                        .cycleDifference.text === "0%",
                                    "fc-red": !_vm.dataPoints.volumeThisMonth
                                      .cycleDifference.positivity
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dataPoints.volumeThisMonth
                                        .cycleDifference.text
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v("\n            This Month\n          ")
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "DashboardEmployeeVolume__data-point-container column column--width-auto"
                      },
                      [
                        _c("label", { staticClass: "fs-24 mb-4" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                new Intl.NumberFormat("en-US", {
                                  currency: "USD",
                                  style: "currency"
                                }).format(
                                  _vm.dataPoints.volumeThisYear.currentCycle
                                )
                              ) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          {
                            staticClass:
                              "DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase"
                          },
                          [
                            _vm.dataPoints.volumeThisYear.cycleDifference
                              .text !== "0%"
                              ? _c(
                                  "svg",
                                  {
                                    class: [
                                      "DashboardEmployeeVolume__svg",
                                      {
                                        "DashboardEmployeeVolume__svg--green":
                                          _vm.dataPoints.volumeThisYear
                                            .cycleDifference.positivity,
                                        "DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip": !_vm
                                          .dataPoints.volumeThisYear
                                          .cycleDifference.positivity
                                      }
                                    ],
                                    attrs: {
                                      fill: "none",
                                      viewBox: "0 0 11 7",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "clip-rule": "evenodd",
                                        d:
                                          "M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z",
                                        fill: "#34AA44",
                                        "fill-rule": "evenodd"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: [
                                  "fs-12 mr-3",
                                  {
                                    "fc-green":
                                      _vm.dataPoints.volumeThisYear
                                        .cycleDifference.positivity &&
                                      _vm.dataPoints.volumeThisYear
                                        .cycleDifference.text !== "0%",
                                    "fc-light":
                                      _vm.dataPoints.volumeThisYear
                                        .cycleDifference.text === "0%",
                                    "fc-red": !_vm.dataPoints.volumeThisYear
                                      .cycleDifference.positivity
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dataPoints.volumeThisYear
                                        .cycleDifference.text
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v("\n            This Year\n          ")
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "DashboardEmployeeVolume__data-point-container column column--width-auto"
                      },
                      [
                        _c("label", { staticClass: "fs-24 mb-4" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                new Intl.NumberFormat("en-US", {
                                  currency: "USD",
                                  style: "currency"
                                }).format(
                                  _vm.dataPoints.volumeRollingYear.currentCycle
                                )
                              ) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          {
                            staticClass:
                              "DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase"
                          },
                          [
                            _vm.dataPoints.volumeRollingYear.cycleDifference
                              .text !== "0%"
                              ? _c(
                                  "svg",
                                  {
                                    class: [
                                      "DashboardEmployeeVolume__svg",
                                      {
                                        "DashboardEmployeeVolume__svg--green":
                                          _vm.dataPoints.volumeRollingYear
                                            .cycleDifference.positivity,
                                        "DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip": !_vm
                                          .dataPoints.volumeRollingYear
                                          .cycleDifference.positivity
                                      }
                                    ],
                                    attrs: {
                                      fill: "none",
                                      viewBox: "0 0 11 7",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "clip-rule": "evenodd",
                                        d:
                                          "M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z",
                                        fill: "#34AA44",
                                        "fill-rule": "evenodd"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: [
                                  "fs-12 mr-3",
                                  {
                                    "fc-green":
                                      _vm.dataPoints.volumeRollingYear
                                        .cycleDifference.positivity &&
                                      _vm.dataPoints.volumeRollingYear
                                        .cycleDifference.text !== "0%",
                                    "fc-light":
                                      _vm.dataPoints.volumeRollingYear
                                        .cycleDifference.text === "0%",
                                    "fc-red": !_vm.dataPoints.volumeRollingYear
                                      .cycleDifference.positivity
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dataPoints.volumeRollingYear
                                        .cycleDifference.text
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n            Rolling 12 Months\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "DashboardEmployeeVolume__data-point-container column column--width-auto"
                      },
                      [
                        _c("label", { staticClass: "fs-24 mb-4" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                new Intl.NumberFormat("en-US", {
                                  currency: "USD",
                                  style: "currency"
                                }).format(
                                  _vm.dataPoints.newVolumeThisMonth.currentCycle
                                )
                              ) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          {
                            staticClass:
                              "DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase"
                          },
                          [
                            _vm.dataPoints.newVolumeThisMonth.cycleDifference
                              .text !== "0%"
                              ? _c(
                                  "svg",
                                  {
                                    class: [
                                      "DashboardEmployeeVolume__svg",
                                      {
                                        "DashboardEmployeeVolume__svg--green":
                                          _vm.dataPoints.newVolumeThisMonth
                                            .cycleDifference.positivity,
                                        "DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip": !_vm
                                          .dataPoints.newVolumeThisMonth
                                          .cycleDifference.positivity
                                      }
                                    ],
                                    attrs: {
                                      fill: "none",
                                      viewBox: "0 0 11 7",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "clip-rule": "evenodd",
                                        d:
                                          "M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z",
                                        fill: "#34AA44",
                                        "fill-rule": "evenodd"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: [
                                  "fs-12 mr-3",
                                  {
                                    "fc-green":
                                      _vm.dataPoints.newVolumeThisMonth
                                        .cycleDifference.positivity &&
                                      _vm.dataPoints.newVolumeThisMonth
                                        .cycleDifference.text !== "0%",
                                    "fc-light":
                                      _vm.dataPoints.newVolumeThisMonth
                                        .cycleDifference.text === "0%",
                                    "fc-red": !_vm.dataPoints.newVolumeThisMonth
                                      .cycleDifference.positivity
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dataPoints.newVolumeThisMonth
                                        .cycleDifference.text
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v("\n            New This Month\n          ")
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "DashboardEmployeeVolume__data-point-container column column--width-auto"
                      },
                      [
                        _c("label", { staticClass: "fs-24 mb-4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.dataPoints.newClientsThisMonth.currentCycle
                            ) + " Clients"
                          )
                        ]),
                        _c(
                          "label",
                          { staticClass: "fs-12 fc-light uppercase" },
                          [
                            _vm.dataPoints.newClientsThisMonth.cycleDifference
                              .text !== "0%"
                              ? _c(
                                  "svg",
                                  {
                                    class: [
                                      "DashboardEmployeeVolume__svg",
                                      {
                                        "DashboardEmployeeVolume__svg--green":
                                          _vm.dataPoints.newClientsThisMonth
                                            .cycleDifference.positivity,
                                        "DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip": !_vm
                                          .dataPoints.newClientsThisMonth
                                          .cycleDifference.positivity
                                      }
                                    ],
                                    attrs: {
                                      fill: "none",
                                      viewBox: "0 0 11 7",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "clip-rule": "evenodd",
                                        d:
                                          "M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z",
                                        fill: "#34AA44",
                                        "fill-rule": "evenodd"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: [
                                  "fs-12 mr-3",
                                  {
                                    "fc-green":
                                      _vm.dataPoints.newClientsThisMonth
                                        .cycleDifference.positivity &&
                                      _vm.dataPoints.newClientsThisMonth
                                        .cycleDifference.text !== "0%",
                                    "fc-light":
                                      _vm.dataPoints.newClientsThisMonth
                                        .cycleDifference.text === "0%",
                                    "fc-red": !_vm.dataPoints
                                      .newClientsThisMonth.cycleDifference
                                      .positivity
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dataPoints.newClientsThisMonth
                                        .cycleDifference.text
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v("\n            New This Month\n          ")
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "button",
                    {
                      class: [
                        "DashboardEmployeeVolume__graph-btn fc-mid fs-15 mr-27",
                        {
                          "DashboardEmployeeVolume__graph-btn--active":
                            _vm.currentChart === "total volume"
                        }
                      ],
                      on: { click: _vm.constructTotalRollingYearVolumeChart }
                    },
                    [_vm._v("\n          Total Volume\n        ")]
                  ),
                  _c(
                    "button",
                    {
                      class: [
                        "DashboardEmployeeVolume__graph-btn fc-mid fs-15 mr-27",
                        {
                          "DashboardEmployeeVolume__graph-btn--active":
                            _vm.currentChart === "new volume"
                        }
                      ],
                      on: {
                        click: _vm.constructNewClientRollingYearVolumeChart
                      }
                    },
                    [_vm._v("\n          New Volume\n        ")]
                  ),
                  _c(
                    "button",
                    {
                      class: [
                        "DashboardEmployeeVolume__graph-btn fc-mid fs-15",
                        {
                          "DashboardEmployeeVolume__graph-btn--active":
                            _vm.currentChart === "new clients"
                        }
                      ],
                      on: { click: _vm.constructNewClientsRollingYearChart }
                    },
                    [_vm._v("\n          New Clients\n        ")]
                  )
                ])
              ])
        ]
      ),
      _c("canvas", {
        ref: "chart",
        staticClass: "DashboardEmployeeVolume__chart"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }