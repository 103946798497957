<template>
  <div class="DashboardEmployeeVolume">
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <!-- LOADING INDICATOR (displays until charts are ready to display) -->
      <div
        v-if="!dataPointsRequestsFinished"
        class="row row--align-center row--justify-center"
        key="loading-indicator-data-points"
      >
        <i class="fa fa-processing fa-spin fa--none fs-60" />
      </div>

      <div
        v-else
        class="column"
        key="charts"
      >
        <!-- DATA POINTS -->
        <div class="DashboardEmployeeVolume__data-points row row--wrap">
          <!-- THIS MONTH -->
          <div class="DashboardEmployeeVolume__data-point-container column column--width-auto">
            <label class="fs-24 mb-4">
              {{
                new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' })
                  .format(dataPoints.volumeThisMonth.currentCycle)
              }}
            </label>
            <label class="DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase">
              <!-- Triangle representing positive/negative difference from previous cycle -->
              <svg
                v-if="dataPoints.volumeThisMonth.cycleDifference.text !== '0%'"
                :class="['DashboardEmployeeVolume__svg', {
                  'DashboardEmployeeVolume__svg--green':
                    dataPoints.volumeThisMonth.cycleDifference.positivity,
                  'DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip':
                    !dataPoints.volumeThisMonth.cycleDifference.positivity,
                }]"
                fill="none"
                viewBox="0 0 11 7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z"
                  fill="#34AA44"
                  fill-rule="evenodd"
                />
              </svg>
              <span
                :class="['fs-12 mr-3', {
                  'fc-green': dataPoints.volumeThisMonth.cycleDifference.positivity
                    && dataPoints.volumeThisMonth.cycleDifference.text !== '0%',
                  'fc-light': dataPoints.volumeThisMonth.cycleDifference.text === '0%',
                  'fc-red': !dataPoints.volumeThisMonth.cycleDifference.positivity,
                }]"
              >
                {{ dataPoints.volumeThisMonth.cycleDifference.text }}
              </span>
              This Month
            </label>
          </div>
          <!-- THIS YEAR -->
          <div class="DashboardEmployeeVolume__data-point-container column column--width-auto">
            <label class="fs-24 mb-4">
              {{
                new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' })
                  .format(dataPoints.volumeThisYear.currentCycle)
              }}
            </label>
            <label class="DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase">
              <!-- Triangle representing positive/negative difference from previous cycle -->
              <svg
                v-if="dataPoints.volumeThisYear.cycleDifference.text !== '0%'"
                :class="['DashboardEmployeeVolume__svg', {
                  'DashboardEmployeeVolume__svg--green':
                    dataPoints.volumeThisYear.cycleDifference.positivity,
                  'DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip':
                    !dataPoints.volumeThisYear.cycleDifference.positivity,
                }]"
                fill="none"
                viewBox="0 0 11 7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z"
                  fill="#34AA44"
                  fill-rule="evenodd"
                />
              </svg>
              <span
                :class="['fs-12 mr-3', {
                  'fc-green': dataPoints.volumeThisYear.cycleDifference.positivity
                    && dataPoints.volumeThisYear.cycleDifference.text !== '0%',
                  'fc-light': dataPoints.volumeThisYear.cycleDifference.text === '0%',
                  'fc-red': !dataPoints.volumeThisYear.cycleDifference.positivity,
                }]"
              >
                {{ dataPoints.volumeThisYear.cycleDifference.text }}
              </span>
              This Year
            </label>
          </div>
          <!-- ROLLING 12 MONTHS -->
          <div class="DashboardEmployeeVolume__data-point-container column column--width-auto">
            <label class="fs-24 mb-4">
              {{
                new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' })
                  .format(dataPoints.volumeRollingYear.currentCycle)
              }}
            </label>
            <label class="DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase">
              <!-- Triangle representing positive/negative difference from previous cycle -->
              <svg
                v-if="dataPoints.volumeRollingYear.cycleDifference.text !== '0%'"
                :class="['DashboardEmployeeVolume__svg', {
                  'DashboardEmployeeVolume__svg--green':
                    dataPoints.volumeRollingYear.cycleDifference.positivity,
                  'DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip':
                    !dataPoints.volumeRollingYear.cycleDifference.positivity,
                }]"
                fill="none"
                viewBox="0 0 11 7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z"
                  fill="#34AA44"
                  fill-rule="evenodd"
                />
              </svg>
              <span
                :class="['fs-12 mr-3', {
                  'fc-green': dataPoints.volumeRollingYear.cycleDifference.positivity
                    && dataPoints.volumeRollingYear.cycleDifference.text !== '0%',
                  'fc-light': dataPoints.volumeRollingYear.cycleDifference.text === '0%',
                  'fc-red': !dataPoints.volumeRollingYear.cycleDifference.positivity,
                }]"
              >
                {{ dataPoints.volumeRollingYear.cycleDifference.text }}
              </span>
              Rolling 12 Months
            </label>
          </div>
          <!-- NEW CLIENT VOLUME THIS MONTH -->
          <div class="DashboardEmployeeVolume__data-point-container column column--width-auto">
            <label class="fs-24 mb-4">
              {{
                new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' })
                  .format(dataPoints.newVolumeThisMonth.currentCycle)
              }}
            </label>
            <label class="DashboardEmployeeVolume__data-point-title fs-12 fc-light uppercase">
              <!-- Triangle representing positive/negative difference from previous cycle -->
              <svg
                v-if="dataPoints.newVolumeThisMonth.cycleDifference.text !== '0%'"
                :class="['DashboardEmployeeVolume__svg', {
                  'DashboardEmployeeVolume__svg--green':
                    dataPoints.newVolumeThisMonth.cycleDifference.positivity,
                  'DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip':
                    !dataPoints.newVolumeThisMonth.cycleDifference.positivity,
                }]"
                fill="none"
                viewBox="0 0 11 7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z"
                  fill="#34AA44"
                  fill-rule="evenodd"
                />
              </svg>
              <span
                :class="['fs-12 mr-3', {
                  'fc-green': dataPoints.newVolumeThisMonth.cycleDifference.positivity
                    && dataPoints.newVolumeThisMonth.cycleDifference.text !== '0%',
                  'fc-light': dataPoints.newVolumeThisMonth.cycleDifference.text === '0%',
                  'fc-red': !dataPoints.newVolumeThisMonth.cycleDifference.positivity,
                }]"
              >
                {{ dataPoints.newVolumeThisMonth.cycleDifference.text }}
              </span>
              New This Month
            </label>
          </div>
          <!-- NEW CLIENTS THIS MONTH -->
          <div class="DashboardEmployeeVolume__data-point-container column column--width-auto">
            <label class="fs-24 mb-4">{{ dataPoints.newClientsThisMonth.currentCycle }} Clients</label>
            <label class="fs-12 fc-light uppercase">
              <!-- Triangle representing positive/negative difference from previous cycle -->
              <svg
                v-if="dataPoints.newClientsThisMonth.cycleDifference.text !== '0%'"
                :class="['DashboardEmployeeVolume__svg', {
                  'DashboardEmployeeVolume__svg--green':
                    dataPoints.newClientsThisMonth.cycleDifference.positivity,
                  'DashboardEmployeeVolume__svg--red DashboardEmployeeVolume__svg--flip':
                    !dataPoints.newClientsThisMonth.cycleDifference.positivity,
                }]"
                fill="none"
                viewBox="0 0 11 7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M5.79331 0.428955L10.6999 6.25028H0.886719L5.79331 0.428955Z"
                  fill="#34AA44"
                  fill-rule="evenodd"
                />
              </svg>
              <span
                :class="['fs-12 mr-3', {
                  'fc-green': dataPoints.newClientsThisMonth.cycleDifference.positivity
                    && dataPoints.newClientsThisMonth.cycleDifference.text !== '0%',
                  'fc-light': dataPoints.newClientsThisMonth.cycleDifference.text === '0%',
                  'fc-red': !dataPoints.newClientsThisMonth.cycleDifference.positivity,
                }]"
              >
                {{ dataPoints.newClientsThisMonth.cycleDifference.text }}
              </span>
              New This Month
            </label>
          </div>
        </div>

        <!-- CHART NAVIGATION -->
        <div class="row">
          <button
            @click="constructTotalRollingYearVolumeChart"
            :class="['DashboardEmployeeVolume__graph-btn fc-mid fs-15 mr-27', {
              'DashboardEmployeeVolume__graph-btn--active': currentChart === 'total volume',
            }]"
          >
            Total Volume
          </button>
          <button
            @click="constructNewClientRollingYearVolumeChart"
            :class="['DashboardEmployeeVolume__graph-btn fc-mid fs-15 mr-27', {
              'DashboardEmployeeVolume__graph-btn--active': currentChart === 'new volume',
            }]"
          >
            New Volume
          </button>
          <button
            @click="constructNewClientsRollingYearChart"
            :class="['DashboardEmployeeVolume__graph-btn fc-mid fs-15', {
              'DashboardEmployeeVolume__graph-btn--active': currentChart === 'new clients',
            }]"
          >
            New Clients
          </button>
        </div>
      </div>
    </transition>

    <!-- CHART (the chart doesn't need to be hidden since an empty canvas is empty) -->
    <!-- By not hiding it, we can begin constructing the chart data immediately without -->
    <!-- worrying about the state controlling whether it's displayed or not -->
    <canvas
      ref="chart"
      class="DashboardEmployeeVolume__chart"
    />
  </div>
</template>

<script>
// Packages
import Chart from 'chart.js'
import moment from 'moment-timezone'
// Helpers
import {
  Client,
  Invoice,
} from '../../utils/api'

export default {
  name: 'DashboardEmployeeVolume',

  async created () {
    this.progressStart()
    this.currentMonth = moment().month()
    await this.getNewClientsThisMonth()
    await this.getNewVolumeThisMonth()
    await this.getVolumeRollingYear()
    await this.getVolumeThisMonth()
    await this.getVolumeThisYear()
    this.progressFinish()
  },

  beforeDestroy() {
    // Get rid of chart data (performance optimization)G
    Object.keys(this.charts).forEach(key => this.$set(this.charts, key, null))
  },

  data () {
    return {
      charts: {
        cities: null,
        new: null,
        total: null,
      },
      chartData: {
        clients: null,
        new: null,
        total: null,
      },
      currentChart: 'total volume',
      currentMonth: null,
      requestsProgress: {
        chart: false,
        newClientsThisMonth: false,
        newVolumeThisMonth: false,
        volumeRollingYear: false,
        volumeThisMonth: false,
        volumeThisYear: false,
      },
      dataPoints: {
        // currentCycle: data for the current data cycle
        // previousCycle: data for previous data cycle
        // cycleDifference: (currentCycle / previousCycle) * 100
        newClientsThisMonth: {
          currentCycle: null,
          cycleDifference: null,
          previousCycle: null,
        },
        newVolumeThisMonth: {
          currentCycle: null,
          cycleDifference: null,
          previousCycle: null,
        },
        volumeRollingYear: {
          currentCycle: null,
          cycleDifference: null,
          previousCycle: null,
        },
        volumeThisMonth: {
          currentCycle: null,
          cycleDifference: null,
          previousCycle: null,
        },
        volumeThisYear: {
          currentCycle: null,
          cycleDifference: null,
          previousCycle: null,
        },
      },
    }
  },

  computed: {
    dataPointsRequestsFinished () {
      if (
        this.requestsProgress.newClientsThisMonth
        && this.requestsProgress.newVolumeThisMonth
        && this.requestsProgress.volumeRollingYear
        && this.requestsProgress.volumeThisMonth
        && this.requestsProgress.volumeThisYear
      ) return true
      return false
    },
  },

  watch: {
    // Construct the default chart when all requests are finished
    // By keeping this synchronous, we can ensure the graph renders after the data points
    // have transitioned into place (there's no transition on the canvas element)
    dataPointsRequestsFinished () {
      if (this.dataPointsRequestsFinished) this.constructTotalRollingYearVolumeChart()
    },
  },

  methods: {
    // a: new
    // b: old
    calculatePercentDifference (a, b) {
      // Divide for decimal difference
      // Multiply for a percent

      // If b (old) is 0, dividing would give undefined, so return a as a percent
      const percent = b === 0 ? a : (a / b)

      // Must convert percent into an understandable number... In other words,
      // what is the percent different from 100% from the previous cycle

      // Ex. A: if a is 60% of what b is, then it's a negative 40% change, so return -40 not 60
      // Ex. B: if a is 190% of what b is, then it's a positive 90% change, so return 90 not 190
      // let humanFormat
      // if (percent <= 100) {
      //     humanFormat = (100 - percent) * -1
      // }
      // humanFormat = Math.abs(percent - 100)
      return {
        positivity: a < b ? 0 : 1,
        text: new Intl.NumberFormat('en-US', { style: 'percent' }).format(percent),
      }
    },

    async constructNewClientsRollingYearChart () {
      // This prevents multiple charts being drawn on top of each other on the same
      // canvas element, which causes weird issues when mouseing over the graphs after
      // multiple have been rendered
      if (this.charts.new) this.charts.new.destroy()
      if (this.charts.total) this.charts.total.destroy()
      this.progressStart()
      this.$set(this.requestsProgress, 'chart', false)

      const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      // Orders month abbrev. w/ current month at last index (rolling year)
      const graphLabelsFromCurrentMonth = [
        ...monthAbbr.slice(this.currentMonth + 1),
        ...monthAbbr.slice(0, this.currentMonth + 1)
      ]

      const ctx = this.$refs.chart.getContext('2d')
      const fillGradient = ctx.createLinearGradient(0, 0, 0, 500)
      // Add three color stops
      fillGradient.addColorStop(0, 'rgba(230, 73, 45, .1)')
      fillGradient.addColorStop(1, 'rgba(255, 255, 255, .1)')

      // Efficiency + Speed
      if (!this.chartData.clients) {
        this.$set(this.chartData, 'clients', await this.getNewClientsRollingYearChartData())
      }

      // Because stupid fucking eSLINT
      // eslint-disable-next-line no-new
      this.$set(this.charts, 'clients', new Chart(ctx, {
        type: 'line',
        data: {
          labels: graphLabelsFromCurrentMonth,
          datasets: [{
            data: this.chartData.clients,
            backgroundColor: fillGradient,
            borderColor: '#E6492D',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            pointBackgroundColor: '#CD3014',
            pointBorderColor: '#FFF',
            pointBorderWidth: 2,
            pointHitRadius: 15,
            pointRadius: 4,
          }],
        },
        options: {
          defaultFontColor: '#9EA0A5',
          defaultFontFamily: "'Roboto', 'sans-serif'",
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 21,
              },
            }],
            yAxes: [{
              gridLines: {
                color: '#EAEDF3',
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 27,
                precision: 0,
              },
            }],
          },
          title: {
            display: false,
          },
        }
      }))

      this.progressFinish()
      this.$set(this.requestsProgress, 'chart', true)
      this.currentChart = 'new clients'
    },

    async constructTotalRollingYearVolumeChart () {
      // This prevents multiple charts being drawn on top of each other on the same
      // canvas element, which causes weird issues when mouseing over the graphs after
      // multiple have been rendered
      if (this.charts.clients) this.charts.clients.destroy()
      if (this.charts.new) this.charts.new.destroy()
      this.progressStart()
      this.$set(this.requestsProgress, 'chart', false)

      const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      // Orders month abbrev. w/ current month at last index (rolling year)
      const graphLabelsFromCurrentMonth = [
        ...monthAbbr.slice(this.currentMonth + 1),
        ...monthAbbr.slice(0, this.currentMonth + 1)
      ]

      const ctx = this.$refs.chart.getContext('2d')
      const fillGradient = ctx.createLinearGradient(0, 0, 0, 500)
      // Add three color stops
      fillGradient.addColorStop(0, 'rgba(26, 144, 235, .1)')
      fillGradient.addColorStop(1, 'rgba(255, 255, 255, .1)')

      // Efficiency + Speed
      if (!this.chartData.total) {
        this.$set(this.chartData, 'total', await this.getTotalRollingYearVolumeChartData())
      }

      // Because stupid fucking eSLINT
      // eslint-disable-next-line no-new
      this.$set(this.charts, 'total', new Chart(ctx, {
        type: 'line',
        data: {
          labels: graphLabelsFromCurrentMonth,
          datasets: [{
            data: this.chartData.total,
            backgroundColor: fillGradient,
            borderColor: '#1991EB',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            pointBackgroundColor: '#2CA0F7',
            pointBorderColor: '#FFF',
            pointBorderWidth: 2,
            pointHitRadius: 15,
            pointRadius: 4,
          }],
        },
        options: {
          defaultFontColor: '#9EA0A5',
          defaultFontFamily: "'Roboto', 'sans-serif'",
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 21,
              },
            }],
            yAxes: [{
              gridLines: {
                color: '#EAEDF3',
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                callback: (value) => {
                  if (value >= 1000) {
                    return `$ ${Number(value / 1000).toFixed(2)}K`
                  }
                  return `$ ${Number(value).toFixed(2)}`
                },
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 27,
              },
            }],
          },
          title: {
            display: false,
          },
        }
      }))

      this.progressFinish()
      this.$set(this.requestsProgress, 'chart', true)
      this.currentChart = 'total volume'
    },

    async constructNewClientRollingYearVolumeChart () {
      // This prevents multiple charts being drawn on top of each other on the same
      // canvas element, which causes weird issues when mouseing over the graphs after
      // multiple have been rendered
      if (this.charts.clients) this.charts.clients.destroy()
      if (this.charts.total) this.charts.total.destroy()
      this.progressStart()
      this.$set(this.requestsProgress, 'chart', false)

      const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      // Orders month abbrev. w/ current month at last index (rolling year)
      const graphLabelsFromCurrentMonth = [
        ...monthAbbr.slice(this.currentMonth + 1),
        ...monthAbbr.slice(0, this.currentMonth + 1)
      ]

      const ctx = this.$refs.chart.getContext('2d')
      const fillGradient = ctx.createLinearGradient(0, 0, 0, 500)
      // Add three color stops
      fillGradient.addColorStop(0, 'rgba(51, 172, 18, .1)')
      fillGradient.addColorStop(1, 'rgba(255, 255, 255, .1)')

      // Efficiency + Speed
      if (!this.chartData.new) {
        this.$set(this.chartData, 'new', await this.getNewVolumeRollingYearChartData())
      }

      // Because stupid fucking eSLINT
      // eslint-disable-next-line no-new
      this.$set(this.charts, 'new', new Chart(ctx, {
        type: 'line',
        data: {
          labels: graphLabelsFromCurrentMonth,
          datasets: [{
            data: this.chartData.new,
            backgroundColor: fillGradient,
            borderColor: '#33AC12',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            pointBackgroundColor: '#33AC12',
            pointBorderColor: '#FFF',
            pointBorderWidth: 2,
            pointHitRadius: 15,
            pointRadius: 4,
          }],
        },
        options: {
          defaultFontColor: '#9EA0A5',
          defaultFontFamily: "'Roboto', 'sans-serif'",
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 21,
              },
            }],
            yAxes: [{
              gridLines: {
                color: '#EAEDF3',
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                callback: (value) => {
                  if (value >= 1000) {
                    return `$ ${Number(value / 1000).toFixed(2)}K`
                  }
                  return `$ ${Number(value).toFixed(2)}`
                },
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 27,
              },
            }],
          },
          title: {
            display: false,
          },
        }
      }))

      this.progressFinish()
      this.$set(this.requestsProgress, 'chart', true)
      this.currentChart = 'new volume'
    },

    // Total # of clients registered this month
    // Compares the new clients this month from the start of the month to today
    // with same period of days of last month (prorated comparison)
    async getNewClientsThisMonth () {
      const previousMonthStartDate = this.getPreviousMonthStart()
      const previousMonthEndDate = this.getTodayOneMonthAgo()
      const thisMonthStartDate = this.getThisMonthStart()
      const thisMonthEndDate = this.getThisMonthEnd()
      console.log(
        `[getNewClientsThisMonth] Getting total # of clients registered from ${thisMonthStartDate}\
        to ${thisMonthEndDate} and comparing with ${previousMonthStartDate} to
        ${previousMonthEndDate}`
      )

      try {
        const newClientsThisMonth = (await Client.getRegisteredClients({
          end: thisMonthEndDate,
          start: thisMonthStartDate,
        })).data
        const newClientsLastMonth = (await Client.getRegisteredClients({
          end: previousMonthEndDate,
          start: previousMonthStartDate,
        })).data
        // Set currentCycle
        this.$set(
          this.dataPoints.newClientsThisMonth,
          'currentCycle',
          newClientsThisMonth.count
        )
        // Set previousCycle
        this.$set(
          this.dataPoints.newClientsThisMonth,
          'previousCycle',
          newClientsLastMonth.count
        )
        // Set cycleDifference
        this.$set(
          this.dataPoints.newClientsThisMonth,
          'cycleDifference',
          this.calculatePercentDifference(
            this.dataPoints.newClientsThisMonth.currentCycle,
            this.dataPoints.newClientsThisMonth.previousCycle,
          )
        )

        this.$set(this.requestsProgress, 'newClientsThisMonth', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Employee Volume "getNewClientsThisMonth"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue getting the total number of registered clients this month'
        })
      }
    },

    // Total of invoices for clients who started this month that were approved
    // Compares the new volume this month from the start of the month to today
    // with same period of days of last month (prorated comparison)
    async getNewVolumeThisMonth () {
      const previousMonthStartDate = this.getPreviousMonthStart()
      const previousMonthEndDate = this.getTodayOneMonthAgo()
      const thisMonthStartDate = this.getThisMonthStart()
      const thisMonthEndDate = this.getThisMonthEnd()
      console.log(
        `[getNewVolumeThisMonth] Getting AGG of invoices approved for new clients registered from \
        ${thisMonthStartDate} to ${thisMonthEndDate} and comparing with ${previousMonthStartDate}
        to ${previousMonthEndDate}`
      )

      try {
        const newVolumeThisMonth = (await Invoice.getNewClientsVolumeAggregate({
          end: thisMonthEndDate,
          start: thisMonthStartDate,
        })).data
        const newVolumeLastMonth = (await Invoice.getNewClientsVolumeAggregate({
          end: previousMonthEndDate,
          start: previousMonthStartDate,
        })).data
        // Set currentCycle
        this.$set(
          this.dataPoints.newVolumeThisMonth,
          'currentCycle',
          newVolumeThisMonth.total_approved_accounts_receivable_amount
        )
        // Set previousCycle
        this.$set(
          this.dataPoints.newVolumeThisMonth,
          'previousCycle',
          newVolumeLastMonth.total_approved_accounts_receivable_amount
        )
        // Set cycleDifference
        this.$set(
          this.dataPoints.newVolumeThisMonth,
          'cycleDifference',
          this.calculatePercentDifference(
            this.dataPoints.newVolumeThisMonth.currentCycle,
            this.dataPoints.newVolumeThisMonth.previousCycle,
          )
        )

        this.$set(this.requestsProgress, 'newVolumeThisMonth', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Employee Volume "getNewVolumeThisMonth"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue getting the aggregate approved invoices for clients who started this month'
        })
      }
    },

    // Used in getVolumeThisYear, getVolumeRollingYear
    getOneYearFromDate (date) {
      return moment(date)
        .subtract(1, 'years')
        .toDate()
    },

    // Used in getNewClientsThisMonth, getNewVolumeThisMonth, getVolumeThisMonth
    getPreviousMonthStart () {
      return moment
        .tz('America/New_York')
        .startOf('month')
        .subtract(1, 'months')
        .toDate()
    },

    // Used in getNewClientsThisMonth, getNewVolumeThisMonth, getVolumeThisMonth,
    // getVolumeThisYear
    getThisMonthEnd () {
      return moment
        .tz('America/New_York')
        .endOf('month')
        .toDate()
    },

    // Used in getNewClientsThisMonth, getNewVolumeThisMonth, getVolumeThisMonth
    getThisMonthStart () {
      return moment
        .tz('America/New_York')
        .startOf('month')
        .toDate()
    },

    // Used in getVolumeThisYear
    getThisYearStart () {
      return moment
        .tz('America/New_York')
        .startOf('year')
        .toDate()
    },

    // Used in getNewClientsThisMonth, getNewVolumeThisMonth, getVolumeThisMonth
    getTodayOneMonthAgo () {
      return moment
        .tz('America/New_York')
        .subtract(1, 'months')
        .toDate()
    },

    // Used in getVolumeThisYear
    getTodayOneYearAgo () {
      return moment
        .tz('America/New_York')
        .subtract(1, 'year')
        .toDate()
    },

    // Total of invoices for clients that were approved over the last 12 months
    // Called in constructNewClientRollingYearVolumeChart
    async getNewVolumeRollingYearChartData () {
      // Make 12 request for each month starting with this month
      // [[start date, end date], [...], ...] starting with this month and going backwards
      // i.e. this month === index 0
      const rollingYearStartAndEndDates = []
      for (let i = 0; i <= 11; i += 1) {
        const month = [
          moment.tz('America/New_York').subtract(i, 'months').startOf('month').toDate(),
          moment.tz('America/New_York').subtract(i, 'months').endOf('month').toDate()
        ]
        rollingYearStartAndEndDates.push(month)
      }
      // Loop through each month's start and end dates and make a request for that data
      const volumeData = []
      // eslint-disable-next-line no-restricted-syntax
      for (const month of rollingYearStartAndEndDates) {
        // eslint-disable-next-line no-await-in-loop
        volumeData.push((await Invoice.getNewClientsVolumeAggregate({
          end: month[1],
          start: month[0],
        })).data.total_approved_accounts_receivable_amount)
      }

      // We must reverse the order of the data, because rollingYearStartAndEndDates START with this
      // month; however, on the graph, we END with this month
      return volumeData.reverse()
    },

    // Called in constructNewClientsRollingYearChart
    async getNewClientsRollingYearChartData () {
      // Make 12 request for each month starting with this month
      // [[start date, end date], [...], ...] starting with this month and going backwards
      // i.e. this month === index 0
      const rollingYearStartAndEndDates = []
      for (let i = 0; i <= 11; i += 1) {
        const month = [
          moment.tz('America/New_York').subtract(i, 'months').startOf('month').toDate(),
          moment.tz('America/New_York').subtract(i, 'months').endOf('month').toDate()
        ]
        rollingYearStartAndEndDates.push(month)
      }
      // Loop through each month's start and end dates and make a request for that data
      const volumeData = []
      // eslint-disable-next-line no-restricted-syntax
      for (const month of rollingYearStartAndEndDates) {
        // eslint-disable-next-line no-await-in-loop
        volumeData.push((await Client.getRegisteredClients({
          end: month[1],
          start: month[0],
        })).data.count)
      }

      // We must reverse the order of the data, because rollingYearStartAndEndDates START with this
      // month; however, on the graph, we END with this month
      return volumeData.reverse()
    },

    // Called in constructTotalRollingYearVolumeChart
    async getTotalRollingYearVolumeChartData () {
      // Make 12 request for each month starting with this month
      // [[start date, end date], [...], ...] starting with this month and going backwards
      // i.e. this month === index 0
      const rollingYearStartAndEndDates = []
      for (let i = 0; i <= 11; i += 1) {
        const month = [
          moment.tz('America/New_York').subtract(i, 'months').startOf('month').toDate(),
          moment.tz('America/New_York').subtract(i, 'months').endOf('month').toDate()
        ]
        rollingYearStartAndEndDates.push(month)
      }
      // Loop through each month's start and end dates and make a request for that data
      const volumeData = []
      // eslint-disable-next-line no-restricted-syntax
      for (const month of rollingYearStartAndEndDates) {
        // eslint-disable-next-line no-await-in-loop
        volumeData.push((await Invoice.getVolumeAggregate({
          end: month[1],
          start: month[0],
        })).data.total_approved_accounts_receivable_amount)
      }

      // We must reverse the order of the data, because rollingYearStartAndEndDates START with this
      // month; however, on the graph, we END with this month
      return volumeData.reverse()
    },

    // AGG of invoices approved over the past 12 months from "this" month
    // Compares the last 12 months of volume starting backwards from today
    // with same period of days of 12 months starting backwars from a year ago today (prorated comparison)
    async getVolumeRollingYear () {
      // Looks from the end of the current month
      const previousRollingYearStartDate = this.getOneYearFromDate(moment())
      const previousRollingYearEndDate = this.getOneYearFromDate(previousRollingYearStartDate)
      const thisRollingYearEndDate = moment().toDate()
      const thisRollingYearStartDate = this.getOneYearFromDate(thisRollingYearEndDate)
      console.log(
        `[getVolumeRollingYear] Getting AGG of invoices approved from ${thisRollingYearStartDate} to
        ${thisRollingYearEndDate} and Comparing from ${previousRollingYearEndDate} to
        ${previousRollingYearStartDate}`
      )

      try {
        const volumeRollingYear = (await Invoice.getVolumeAggregate({
          end: thisRollingYearEndDate,
          start: thisRollingYearStartDate,
        })).data
        const volumeRollingPreviousYear = (await Invoice.getVolumeAggregate({
          end: previousRollingYearStartDate,
          start: previousRollingYearEndDate,
        })).data
        // Set currentCycle
        this.$set(
          this.dataPoints.volumeRollingYear,
          'currentCycle',
          volumeRollingYear.total_approved_accounts_receivable_amount
        )
        // Set previousCycle
        this.$set(
          this.dataPoints.volumeRollingYear,
          'previousCycle',
          volumeRollingPreviousYear.total_approved_accounts_receivable_amount
        )
        // Set cycleDifference
        this.$set(
          this.dataPoints.volumeRollingYear,
          'cycleDifference',
          this.calculatePercentDifference(
            this.dataPoints.volumeRollingYear.currentCycle,
            this.dataPoints.volumeRollingYear.previousCycle,
          )
        )

        this.$set(this.requestsProgress, 'volumeRollingYear', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Employee Volume "getVolumeRollingYear"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message:
            'There was an issue getting the aggregate invoices approved over the past 12 months from "this" month'
        })
      }
    },

    // AGG of invoices approved "this" month
    // Compares the volume this month from the start of the month to today
    // with same period of days of last month (prorated comparison)
    async getVolumeThisMonth () {
      const previousMonthStartDate = this.getPreviousMonthStart()
      const previousMonthEndDate = this.getTodayOneMonthAgo()
      const thisMonthStartDate = this.getThisMonthStart()
      const thisMonthEndDate = this.getThisMonthEnd()
      console.log(
        `[GetVolumeThisMonth] Getting AGG of invoices approved from ${thisMonthStartDate} to ${thisMonthEndDate}`
      )

      try {
        const volumeThisMonth = (await Invoice.getVolumeAggregate({
          end: thisMonthEndDate,
          start: thisMonthStartDate,
        })).data
        const volumePreviousMonth = (await Invoice.getVolumeAggregate({
          end: previousMonthEndDate,
          start: previousMonthStartDate,
        })).data
        // Set currentCycle
        this.$set(
          this.dataPoints.volumeThisMonth,
          'currentCycle',
          volumeThisMonth.total_approved_accounts_receivable_amount
        )
        // Set previousCycle
        this.$set(
          this.dataPoints.volumeThisMonth,
          'previousCycle',
          volumePreviousMonth.total_approved_accounts_receivable_amount
        )
        // Set cycleDifference
        this.$set(
          this.dataPoints.volumeThisMonth,
          'cycleDifference',
          this.calculatePercentDifference(
            this.dataPoints.volumeThisMonth.currentCycle,
            this.dataPoints.volumeThisMonth.previousCycle,
          )
        )

        this.$set(this.requestsProgress, 'volumeThisMonth', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Employee Volume "getVolumeThisMonth"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue getting the aggregate invoices approved "this" month'
        })
      }
    },

    // AGG of invoices approved the current year
    // Compares the volume this year from the start of the year to today
    // with same period of days of last year (prorated comparison)
    async getVolumeThisYear () {
      const previousYearEndDate = this.getTodayOneYearAgo()
      const previousYearStartDate = this.getOneYearFromDate(this.getThisYearStart())
      const thisYearEndDate = this.getThisMonthEnd()
      const thisYearStartDate = this.getThisYearStart()
      console.log(
        `[GetVolumeThisYear] Getting AGG of invoices approved from ${thisYearStartDate} to
        ${thisYearEndDate} comparing with AGG of invoices approved from ${previousYearStartDate}
        to ${previousYearEndDate}`
      )

      try {
        const volumeThisYear = (await Invoice.getVolumeAggregate({
          end: thisYearEndDate,
          start: thisYearStartDate,
        })).data
        const volumePreviousYear = (await Invoice.getVolumeAggregate({
          end: previousYearEndDate,
          start: previousYearStartDate,
        })).data
        this.$set(this.dataPoints, 'volumeThisYear', volumeThisYear)
        // Set currentCycle
        this.$set(
          this.dataPoints.volumeThisYear,
          'currentCycle',
          volumeThisYear.total_approved_accounts_receivable_amount
        )
        // Set previousCycle
        this.$set(
          this.dataPoints.volumeThisYear,
          'previousCycle',
          volumePreviousYear.total_approved_accounts_receivable_amount
        )
        // Set cycleDifference
        this.$set(
          this.dataPoints.volumeThisYear,
          'cycleDifference',
          this.calculatePercentDifference(
            this.dataPoints.volumeThisYear.currentCycle,
            this.dataPoints.volumeThisYear.previousCycle,
          )
        )

        this.$set(this.requestsProgress, 'volumeThisYear', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Employee Volume "getVolumeThisYear"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message:
            'There was an issue getting the aggregate invoices approved since the beginning of the year'
        })
      }
    },
  },
}
</script>

<style lang="sass">
.DashboardEmployeeVolume

  &__chart
    margin-top: rem(36px)
    // Fixes scroll height changes when changing graphs due to graph destroying first
    min-height: rem(450px)

  &__data-point-container
    flex: 0 0 20%
    padding: 0 rem(8px)

    &:first-child
      padding-left: 0

    &:last-child
      padding-right: 0

  &__data-point-title
    display: inline-block
    position: relative

  &__data-points
    margin-bottom: rem(83px)

  &__graph-btn

    &--active
      border-bottom: rem(2px) solid $branding
      color: $text-dark
      font-weight: 500
      padding-bottom: rem(3px)

  &__svg
    width: rem(14px)

    &--flip
      transform: rotate(0.5turn)

    &--green
      path
        fill: $success

    &--red
      path
        fill: $danger
</style>